* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
}

html,
body {
  overflow: hidden;
}

html,
body,
#__next,
main {
  width: 100%;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  overflow: auto;
  font-family:  Signifier, ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;

}

.content{
  border-radius: 8px;
  color: #000;
  background-size:cover;
}




